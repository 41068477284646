<template>
	<div class="i">
		<div class="i-title">基础信息</div>
		<el-row>
			<el-col :span="24">
				<span>公司类型:</span>
				<p>{{obj.enterpriseType}}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="8">
				<span>网址:</span>
				<p>{{obj.website}}</p>
			</el-col>
			<el-col :span="8">
				<span>办公地址:</span>
				<p>{{obj.address}}</p>
			</el-col>
			<el-col :span="8">
				<span>传真:</span>
				<p>{{obj.fax}}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<span>营业执照</span>
				<div class="p">
					<el-image
              :preview-src-list="obj.enterpriseLicense && obj.enterpriseLicense.length ? [obj.enterpriseLicense[0].url] : ''"
						:src="obj.enterpriseLicense && obj.enterpriseLicense.length ? obj.enterpriseLicense[0].url : ''"
						style="width: 300px;height: 180px;object-fit: cover;"
					/>
				</div>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="8">
				<span>员工人数:</span>
				<p>{{obj.staffNum}}</p>
			</el-col>
			<el-col :span="8">
				<span>年营业额:</span>
				<p>{{obj.annualTurnover}}</p>
			</el-col>
			<el-col :span="8">
				<span>竞争对手:</span>
				<p>{{obj.competitor}}</p>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="8">
				<span>付款方式:</span>
				<p>{{obj.payStatus}}</p>
			</el-col>
			<el-col :span="8">
				<span>信用额度:</span>
				<p>{{obj.creditLimit}}</p>
			</el-col>
			<el-col :span="8">
				<span>是否加企业微信:</span>
				<p>{{obj.isWechatFriend}}</p>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex'
	export default{
		data(){
			return{
				obj:{},
			}
		},
		props:['info','eid'],
		methods:{
			...mapActions('myMessageModule',[
				'enterpriseTreeList'
			]),
			init(){
				var obj = JSON.parse(JSON.stringify(this.info));
				obj.enterpriseLicense = JSON.parse(obj.enterpriseLicense || '[]');
				obj.staffNum = this.getText('staffNum',obj.staffNum);
				obj.annualTurnover = this.getText('annualTurnover',obj.annualTurnover);
				obj.payStatus  = this.getText('paymentTypes',obj.payStatus);
				obj.isWechatFriend = this.getText('isWechatFriend',obj.isWechatFriend);
				this.obj = obj;
				this.getTypes();
			},
			getTypes(){
				if(this.obj && this.obj.enterpriseType){
					this.enterpriseTreeList({}).then(res => {
						let {code,data} = res.data;
						if(code === '0'){
							this.obj.enterpriseType = this.getTypeText(data,this.obj.enterpriseType)
						}
					})
				}
			},
			getTypeText(data,type,text){  //根据type匹配ids
				text = text || '';
				data.map(item => {
					if(item.id === type){
						text = text || item.categoryName;
					}else if(item.children && item.children.length){
						text = text || this.getTypeText(item.children,type,text);
					}
				})
				return text;
			},
			getText(type,key){
				return this[type].some(v => v.type === key) ? this[type].filter(v => v.type === key)[0].title : '';
			}
		},
		computed:{
			...mapState('myMessageModule',[
				'staffNum',  //员工人数
				'isWechatFriend',  //是否加企业信息
				'annualTurnover',  //年营业额
			]),
			...mapState('purchaseMudule',[
				'paymentTypes',  //付款方式
			]),
		},
		created(){
			this.init()
		},
		watch:{
			info(v){
				this.init()
			}
		}
	}
</script>

<style scoped lang="less">
	/deep/ .el-row{
		padding:15px 0;
		.el-col{
			font-size: 14px;
			display: flex;
			align-items: center;
			span{
				color: #999999;
				width: 120px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			p{
				flex: 1;
			}
		}
	}
	.i-title{
		color: @dh-color;
		font-size: 14px;
		font-weight: bold;
		padding-top: 10px;
	}
	.c-item{
		display: flex;
		align-items: center;
		padding: 10px 0;
		span{
			color: #0A25FF;
			margin-right: 10px;
			text-decoration: underline;
			cursor: pointer;
			width: inherit !important;
		}
	}
</style>
