<template>
	<lida-table
		:cutHeight="30" 
		:data="list" 
		border 
		:page="page"
		:pageSize="pageSize"
		@change="pageChange"
		:total="total"
		ref="table">
		<el-table-column type="index" label="序号" width="55" />
		<lida-table-column label="客户名称">
			<template #default="scope">
				<div>{{scope.row.realName}}</div>
			</template>
		</lida-table-column>
		<lida-table-column label="客户手机号">
			<template #default="scope">
				<div>{{scope.row.mobilePhone}}</div>
			</template>
		</lida-table-column>
		<lida-table-column label="客户编码">
			<template #default="scope">
				<div>{{scope.row.customerNumber}}</div>
			</template>
		</lida-table-column>
		<lida-table-column label="归属销售/商务">
			<template #default="scope">
				<div>{{scope.row.userName}}</div>
			</template>
		</lida-table-column>
		<lida-table-column label="工作角色">
			<template #default="scope">
				<div>{{scope.row.jobRole}}</div>
			</template>
		</lida-table-column>
		<lida-table-column label="公司职位">
			<template #default="scope">
				<div>{{scope.row.jobPost}}</div>
			</template>
		</lida-table-column>
		<lida-table-column label="客户标签">
			<template #default="scope">
				<el-tag 
					effect="dark" 
					size="small"
					:type="scope.row.identifyLabel === '0' ? 'success' : scope.row.identifyLabel === '1' ? 'danger' : scope.row.identifyLabel === '2' ? 'warning' : 'info'">
						{{scope.row.identifyLabelText}}
					</el-tag>
			</template>
		</lida-table-column>
		<!-- <lida-table-column label="操作" width="50">
			<template #default="scope">
				<div class="see" @click="seeUser(scope.row)">查看</div>
			</template>
		</lida-table-column> -->
	</lida-table>
</template>

<script>
	import { mapState } from 'vuex';
	export default{
		data(){
			return{
				list:[],  //员工列表
			}
		},
		computed:{
			...mapState('supplierModule',[
				'identifyLabel',  //客户标签
			])
		},
		props:['info','eid'],
		methods:{
			seeUser(row){ //查看用户的点击事件
				console.log(row);
			},
			init(){
				if(this.info.userList && this.info.userList.length){
					this.info.userList.map(item => {
						item.identifyLabelText = this.getText(item.identifyLabel);
					})
					this.list = this.info.userList;
				}
			},
			getText(type){
				return this.identifyLabel.filter(v => v.type === type).length ? 
				this.identifyLabel.filter(v => v.type === type)[0].title : '';
			}
		},
		created(){
			this.init();
		},
		watch:{
			info(v){
				this.init();
			}
		}
	}
</script>

<style scoped lang="less">
	.see{
		color: #D84146;
	}
</style>
